import { Link } from "gatsby";
import React, { useState } from "react";
import { PopupButton } from "@typeform/embed-react";

import sectionShape from "../../assets/image/svg/footer-shape.svg";
export const PricingData = [
  {
    title: "Starter",
    planId: "starter",
    list: [
      "Unlimited discord servers",
      "Unlimited slack workspaces",
      "Spam Detection (coming soon)",
      "Manage Violations (coming soon)",
      "5000 events",
      "then $1 per 1000 events",
      "1000 characters/event",
      // "1 User",
    ],
    price: "15",
    reducedPrice: "9",
  },
  {
    title: "Pro",
    planId: "pro",
    list: [
      "Everything in Starter +",
      "Product Integration (coming soon)",
      "Mixpanel Integration (coming soon)",
      "Segment Integration (coming soon)",
      "Zapier Integration (coming soon)",
      "20,000 events",
      "then $0.7 per 1000 events",
      "2000 characters/event",
      // "5  Users",
    ],
    price: "59",
    reducedPrice: "29",
  },
  {
    title: "Enterprise",
    planId: "enterprise",
    list: ["A custom plan just for you"],
  },
];

const Pricing = ({ className, ...rest }) => {
  const [plan, setPlan] = useState("monthly");

  const contactSales = () => {
    const sontel = document.getElementById("SonetelWidget")?.firstChild

    if (sontel) {
      sontel.click();
    } else {
      window.open("mailto:support@modwise.ai")
    }
  };

  return (
    <div id="pricing" className={`position-relative ${className}`} {...rest}>
      <div className="container">
        {/* Section Title */}
        <div className="mb-13 mb-lg-13">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-10">
              <div
                className="mb-10 mb-lg-0 text-center text-lg-left"
                data-aos="fade-right"
                data-aos-delay={0}
              >
                <h2 className="font-size-10 pr-md-10 pr-xl-0 mb-0">Pricing</h2>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 text-center text-md-right">
              {/* <div
                className="btn-section"
                data-aos="fade-left"
                data-aos-delay={500}
              >
                <div className="btn-toggle-square active mx-3 price-deck-trigger rounded-10 bg-golden-yellow">
                  <button
                    className={`text-break btn-reset focus-reset ${plan ===
                      "yearly" && "active"}`}
                    onClick={() => setPlan("yearly")}
                  >
                    Yearly
                  </button>
                  <button
                    className={`text-break btn-reset focus-reset ${plan ===
                      "monthly" && "active"}`}
                    onClick={() => setPlan("monthly")}
                  >
                    Monthly
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* End Section Title */}

        {/* Table Main Body */}
        <div className="table-body">
          <div className="row justify-content-center">
            {PricingData.map(({ title, list, price, reducedPrice }, index) => {
              return (
                <div className="col-lg-12 col-sm-6" key={index}>
                  <div
                    className="border-top pt-10 pb-8"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-5 mb-lg-0">
                          <h3 className="font-size-7">{title}</h3>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="">
                          <ul className="list-unstyled font-size-6">
                            {list.map((item, index) => (
                              <li
                                className="heading-default-color mb-4"
                                style={{
                                  color: item.includes("coming")
                                    ? "GrayText"
                                    : "black",
                                }}
                                key={index}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="pr-lg-15 pr-xl-10 mb-5 mb-lg-0">
                          <h2 className="mb-0 font-weight-medium">
                            {reducedPrice ? (
                              <>
                                <s className="font-size-8 mr-4">${price}</s>
                                <span className="font-size-11">
                                  ${reducedPrice}
                                </span>
                              </>
                            ) : (
                              price && (
                                <span className="font-size-11">${price}</span>
                              )
                            )}
                          </h2>
                          {price && (
                            <p className="mb-0 font-size-5 pr-xl-22">
                              ${reducedPrice || price} per month, billed {plan}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="mb-5 mb-lg-0 mt-4 text-left text-lg-right">
                          {price ? (
                            <Link
                              className="btn btn-outline-gray-1 btn-2 border-width-2 rounded-5 gr-hover-bg-blue-3 heading-default-color"
                              to="/signup"
                            >
                              Start for Free
                            </Link>
                          ) : (
                            <button
                              className="btn btn-outline-gray-1 btn-2 border-width-2 rounded-5 gr-hover-bg-blue-3 heading-default-color"
                              onClick={contactSales}
                            >
                              Contact Sales
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-12 col-sm-6">
              <div className="border-top pt-10 pb-8" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="mb-5 mb-lg-0">
                      <h3 className="font-size-7">Limited Beta</h3>
                    </div>
                  </div>
                  <div className="col-lg-4">Everything in Starter</div>
                  <div className="col-lg-3">
                    <div className="pr-lg-15 pr-xl-10 mb-5 mb-lg-0">
                      <h2 className="mb-0 font-weight-medium"></h2>
                      <p className="mb-0 font-size-5 pr-xl-22">Free forever</p>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-5 mb-lg-0 mt-4 text-left text-lg-right">
                      <PopupButton
                        id="Ccv72zRC"
                        className="btn btn-outline-gray-1 btn-2 border-width-2 rounded-5 gr-hover-bg-blue-3 heading-default-color"
                      >
                        Get it!
                      </PopupButton>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Table Main Body */}
      </div>
      <div className="pricing-bottom-shape d-none d-sm-block">
        <img
          src={sectionShape}
          alt=""
          data-aos="fade-left"
          data-aos-delay={500}
        />
      </div>
    </div>
  );
};

export default Pricing;
